angular.module('app.controllers').controller('AuthCtrl', ['$scope', '$rootScope', '$location', 'UI'].append(function(self, root, $location, UI) {
  var params;
  params = $location.search();
  if (params && angular.isDefined(params.password_restore)) {
    $location.path("/passwort-vergessen");
  }
  if (params && angular.isDefined(params.auth_ticket)) {
    $location.path("/passwort-setzen");
  }
  if (params && angular.isDefined(params.confirm)) {
    $location.path("/passwort-confirm");
  }
  if (params && angular.isDefined(params.validated)) {
    $location.path("/verification");
  }
}));
